var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-card',{staticClass:"rounded-md mx-3 mx-md-12",staticStyle:{"max-width":"900px"},attrs:{"align":"start"}},[_c('v-card-title',{staticClass:"pkg-title"},[_c('v-icon',{staticClass:"pkg-icon",attrs:{"color":"white","size":"96px"}},[_vm._v("mdi-package-variant")]),_c('span',{staticStyle:{"z-index":"1"}},[_vm._v(_vm._s(_vm.item.name))])],1),_c('v-card-text',{staticClass:"pt-3 px-6 pkg-text",staticStyle:{"text-align":"justify"}},[(_vm.item['homepage'])?[_c('span',{staticClass:"subtitle-2"},[_vm._v("Homepage")]),_c('br'),_c('span',[_c('a',{attrs:{"href":_vm.item.homepage}},[_vm._v(_vm._s(_vm.item.homepage))]),_c('br')]),_c('br')]:_vm._e(),(_vm.item['maintainers'])?[_c('span',{staticClass:"subtitle-2"},[_vm._v("Maintainers")]),_c('br'),_c('span',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s((Array.isArray(_vm.item.maintainers) ? _vm.item.maintainers : [_vm.item.maintainers] ).join(", "))+" "),_c('br')]),_c('br')]:_vm._e(),_c('span',{staticClass:"subtitle-2"},[_vm._v("Latest Version")]),_c('br'),_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.item["version"] || _vm.item["version-string"] || _vm.item["version-semver"]))]),(_vm.item['license'])?[_c('br'),_c('br'),_c('span',{staticClass:"subtitle-2"},[_vm._v("License")]),_c('br'),_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.item.license))])]:_vm._e(),(_vm.item['supports'])?[_c('br'),_c('br'),_c('span',{staticClass:"subtitle-2"},[_vm._v("Supports")]),_c('br'),_vm._l((_vm.resolvePlatforms(_vm.item['supports'])),function(pf){return [_c('v-chip',{key:pf,staticClass:"ma-1",staticStyle:{"color":"white"},attrs:{"x-small":"","color":pf[0] == '!' ? 'red' : 'green'}},[_vm._v(_vm._s(pf[0] == "!" ? pf.slice(1) : pf))])]})]:_vm._e(),_c('v-divider',{staticClass:"mx-0 my-5"}),_vm._l((Array.isArray(_vm.item.description)
              ? _vm.item.description
              : [_vm.item.description]),function(par,idx){return [(
                _vm.item['description'] &&
                Array.isArray(_vm.item.description) &&
                _vm.item.description.length > 1 &&
                idx == 0
              )?_c('span',{key:idx,staticClass:"mb-4 font-weight-medium font-italic"},[_vm._v(" "+_vm._s(par)),_c('br'),_c('br')]):_vm._e(),(
                _vm.item['description'] &&
                (typeof _vm.item.description == 'string' ||
                  _vm.item.description.length == 1 ||
                  idx != 0)
              )?_c('span',{key:idx,staticClass:"mb-4"},[_vm._v(" "+_vm._s(par)),_c('br')]):_vm._e()]}),(_vm.item['features'])?[_c('v-divider',{staticClass:"mx-0 my-5"}),_c('span',{staticClass:"subtitle-2"},[_vm._v("Features")]),_c('br'),(_vm.item['default-features'])?_c('span',{staticClass:"font-weight-light"},[_vm._v(" Default "),_vm._l((_vm.item['default-features']),function(df){return _c('v-chip',{key:df,staticClass:"ma-1",attrs:{"x-small":""}},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":`#${df}`}},[_vm._v(" "+_vm._s(df)+" ")])])})],2):_vm._e(),_c('v-list',[_vm._l((Object.keys(_vm.item.features)),function(feature,idx){return [(idx != 0)?_c('v-divider',{key:idx,staticClass:"ml-5 mr-2 my-2"}):_vm._e(),_c('v-list-item',{key:feature,staticStyle:{"width":"100%"},attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2"},[_c('a',{attrs:{"name":feature}}),_vm._v(" "+_vm._s(feature)+" ")]),(_vm.item.features[feature]['description'])?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.item.features[feature]["description"])+" ")]):_vm._e(),(_vm.item.features[feature]['dependencies'])?[_c('span',{staticClass:"font-weight-light"},[_vm._v(" Depends "),_vm._l((_vm.item.features[feature][
                            'dependencies'
                          ]),function(dep,idx){return _c('v-tooltip',{key:`${
                            typeof dep == 'string' ? dep : dep.name
                          }-${idx}`,attrs:{"bottom":"","disabled":!dep['platform']},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"x-small":""}},'v-chip',attrs,false),on),[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/index/pkg/${
                                  typeof dep == 'string' ? dep : dep.name
                                }`}},[_vm._v(" "+_vm._s(typeof dep == "string" ? dep : dep.name)+" "),(dep['features'])?[_c('span',{staticClass:"font-italic"},[_vm._v(" [ "),_vm._l((dep.features),function(feat,idx){return _c('router-link',{key:`${
                                        typeof dep == 'string'
                                          ? dep
                                          : dep.name
                                      }-${feat}`,staticStyle:{"text-decoration":"none"},attrs:{"to":`/index/pkg/${
                                        typeof dep == 'string'
                                          ? dep
                                          : dep.name
                                      }#${feat}`}},[_vm._v(" "+_vm._s(`${idx ? ", " : ""}${feat}`)+" ")])}),_vm._v(" ] ")],2)]:_vm._e()],2)],1)]}}],null,true)},[_vm._l((_vm.resolvePlatforms(dep['platform'] || '')),function(pf){return [_c('v-chip',{key:pf,staticClass:"ma-1",staticStyle:{"color":"white"},attrs:{"x-small":"","color":pf[0] == '!' ? 'red' : 'green'}},[_vm._v(_vm._s(pf[0] == "!" ? pf.slice(1) : pf))])]})],2)})],2)]:_vm._e()],2)],1)]})],2)]:_vm._e(),(_vm.item['dependencies'])?[_c('v-divider',{staticClass:"mx-0 my-5"}),_c('span',{staticClass:"subtitle-2"},[_vm._v("Dependencies")]),_c('br'),_vm._l((_vm.item['dependencies']),function(dep,idx){return _c('v-chip',{key:`${typeof dep == 'string' ? dep : dep.name}-${idx}`,staticClass:"ma-1",attrs:{"small":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/index/pkg/${typeof dep == 'string' ? dep : dep.name}`}},[_vm._v(" "+_vm._s(typeof dep == "string" ? dep : dep.name)+" "),(dep['features'])?[_c('span',{staticClass:"font-italic"},[_vm._v(" [ "),_vm._l((dep.features),function(feat,idx){return _c('router-link',{key:`${
                        typeof dep == 'string' ? dep : dep.name
                      }-${feat}`,staticStyle:{"text-decoration":"none"},attrs:{"to":`/index/pkg/${
                        typeof dep == 'string' ? dep : dep.name
                      }#${feat}`}},[_vm._v(" "+_vm._s(`${idx ? "," : ""}${feat}`)+" ")])}),_vm._v(" ] ")],2)]:_vm._e()],2)],1)})]:_vm._e()],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }